<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Platební služby - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-6">
        </div>
        <div class="text-right col-md-6">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowKey="record => record.id"
        :columns="columns"
        :loading="loading"
        :dataSource="paymentServices"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <template slot="id" slot-scope="text">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >#{{fragment}}</mark>
              <template v-else>#{{fragment}}</template>
            </template>
          </span>
          <template v-else>#{{text}}</template>
        </template>
        <template slot="customRender" slot-scope="text">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
        <template v-else>{{text}}</template>
        </template>
        <template slot="footer">
          {{ paymentServices.length }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'

export default {
  components: { ActionTools },
  data: function () {
    return {
      searchText: '',
      loading: false,
      routerOptions: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.id - b.id,
          scopedSlots: {
            customRender: 'id',
          },
        },
        {
          title: 'Název',
          dataIndex: 'name',
          sorter: (a, b) => {
            return a.name.localeCompare(b.name)
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
      ],
    }
  },
  computed: {
    paymentServices: function () {
      return this.$store.getters['paymentServices/currentLanguage'](this.searchText)
    },
  },
  methods: {
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('paymentServices/list')
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
  },
  created() {
    if (this.paymentServices.length === 0) {
      this.handleRefresh()
    }
  },
}
</script>

<style lang="scss" module scoped>
  @import "./style.module.scss";
</style>
